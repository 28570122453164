@import '../../colors';
.cars_table{
  color:$white;
  width:100%;
  text-align:left;
  border:2px solid $background;
  border-radius:8px;

  .date{
    display: flex;
    justify-content: space-around;
  }

  .text-center{
    text-align: center!important;
  }
}