.brand-head{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.brand-head h2{
  color:white;
  margin: 0;
}

.brand_grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}
