@import './colors';

body {
  margin:20px;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  background: $background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  min-height: 100vh;
  padding: 20px;
}

.button{
  margin-top: 5px!important;
  border-color: white!important;
  color:$white!important;
  text-transform: none!important;
  font-weight: bold!important;
}

.auth{
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

table {
  font-family: Roboto, sans-serif;
  border-collapse: collapse;
  width: 100%;

  tr{
    td{
      background-color: $grey;
    }
    &:hover{
      //background-color: rgba(30, 30, 30, 0.20)!important;
      color: darken($white, 50);
    }
  }
  thead{
    td{
      background-color: $dark-grey;
      text-align: center;
    }
    tr:hover{
      color: $white;
    }
  }
  td, th {
    border: 2px solid $background;
    text-align: left;
    padding: 8px;

  }
}


//
//tr:nth-child(even) {
//  background-color: #4f4f4f;
//}
//
//tr:nth-child(even):hover {
//  background-color: #f3f3f3;
//  color:black;
//}

label{
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  color:$white;
  font-size: 10px;
}

input[type='text'], input[type='number'], input[type='url'], select{
  color:$white!important;
  background-color: $black;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px 16px;
  outline: none;
  &:hover, &:focus{
    border: 1px solid $grey;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select{
  option{
    background-color: $grey;
  }
}

h3{
  color:$white;
  margin:0;
}

h4{
  color:$white;
  margin:0;
}


.button-filled{
  padding: 10px!important;
  background-color: $grey!important;
  color: white!important;
}

.window-grey{
  padding: 24px;
  background-color: $grey;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

a{
  text-decoration: none;
  color:white;
}

a:hover{
  text-decoration: none;
  cursor: pointer;
  color: #bebebe;
}
